import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

const vuetify = new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        themes: {
            light: {
                primary: '#37b6ae',
                secondary: '#4c524d',
                accent: '#7cded8',
            },
        },
    },
});

Vue.use(Vuetify);

export default vuetify;
